<template>
    <div
        :class="isShowedSidebar ? 'sidebar-opened' : 'sidebar-closed'"
        class="content-layout-wrapper"
    >
        <div class="content">
            <content-header
                v-if="links && links.length"
                :links="links"
            />
            <slot name="filters" />
            <slot name="content" />
            <slot name="toolbar" />
            <slot name="hiddenModals" />
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus'

import ContentHeader from '@/components/CustomTable/ContentHeader'

export default {
    components: {
        ContentHeader
    },
    props: {
        links: {
            type: Array
        }
    },
    data() {
        return {
            isShowedSidebar: true
        }
    },
    mounted() {
        eventBus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    beforeDestroy() {
        eventBus.$off('toggleSidebar')
    }
}
</script>

<style lang='scss'>
    @import "@/assets/styles/dashboard/components/content/content-layout";
</style>
