<template>
    <div class="content__header">
        <div class="content__header-links">
            <router-link
                v-for="(link, index) in links"
                :key="index"
                :to="{ name: link.to }"
                :disabled="$route.path === link.to ? true : false"
                :event="$route.path === link.to ? '' : 'click'"
                class="content__header-links__item"
            >
                {{ link.title }}
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ContentHeader',
    props: {
        links: {
            type: Array
        }
    }
}
</script>

<style lang='scss'>
    @import "@/assets/styles/dashboard/components/content/_content-navbar";
</style>
