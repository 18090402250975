<template>
    <content-layout>
        <template #content>
            <JsonEditor
                v-model="JsonMenu"
                :options="{
                    confirmText: 'confirm',
                    cancelText: 'cancel',
                }"
                :obj-data="JsonMenu"
            />

            <custom-button
                default
                @on-btn-click="submit"
            >
                Save
            </custom-button>
        </template>
    </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout';
import { getMenu, updateMenu } from '@/services/menuEditorService';

export default {
    name: 'MenuBuilder',
    components: { ContentLayout },
    data() {
        return {
            JsonMenu: {}
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        getData() {
            getMenu().then((resp) => { this.JsonMenu = resp })
        },
        submit() {
            updateMenu(this.JsonMenu)
                .then(() => this.$bus.$emit('showSnackBar', 'Menu was changed', 'success'))
                .catch((e) => this.$bus.$emit('showSnackBar', e.response?.data?.message || 'Invalid data', 'error'))
        }
    }
}
</script>

<style scoped>

</style>
